import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router";

import "../../../node_modules/react-toastify/dist/ReactToastify.css";

export class CadastroCpfCnpj extends Component {
  constructor(props) {
    super(props);
    this.state = {
      txtConfirmar: "Confirmar",
      selectOptions: [],
      id: "",
      nomeCompleto: "",
      cpf: "",
      ddd: "",
      telefone: "",
      certificado: "",
      Confirm: "Confirm",
      inputPreenchido: false,
      inputNumero: false,
      btnConfimar: true,
      idAssistServico: 0,
      inputTelefonePreenchido: false,
      inputCPFPreenchido: false,
      inputNomeCompletoPreenchido: false,
      inputDDDPreenchido: false,
      btnConfimarDados: true,
      inputCertificadoPreenchido: false,
      aceite: false,
    };

    this.onSalvar = this.onSalvar.bind(this);
    this.validate = this.validate.bind(this);
  }

  async componentDidMount() {}

  onKeyPressCPF = (e) => {
    var reg = new RegExp("^[0-9]*$");

    const valor = e.target.value;

    if (reg.test(e.key) && valor.length <= 14) {
      this.setState({ cpf: valor });
      this.setState({ inputCPFPreenchido: true });
    } else {
      e.preventDefault();
    }
  };

  onKeyPressCertificado = (e) => {
    var reg = new RegExp("^[0-9]*$");
    const valor = e.target.value;

    if (reg.test(e.key) && valor.length <= 16) {
      this.setState({ inputCertificadoPreenchido: true });
    } else {
      e.preventDefault();
    }
  };

  aceiteOnChange = (e) => {
    this.setState({ aceite: e.target.checked });
  };

  validate() {
    let retorno = true;
    if (this.state.nomeCompleto == "") {
      toast.error("O campo Nome Completo é obrigatório.");
      this.setState({ showSaveButton: true });

      retorno = false;
    }
    if (this.state.cpf == "") {
      toast.error("O campo CPF é obrigatório.");
      retorno = false;
    }

    let celular = this.state.telefone;
    if (celular == "") {
      toast.error("O campo Telefone é obrigatório.");
      retorno = false;
    } else if (celular.indexOf("__") > 0) {
      toast.error("O campo Telefone é inválido.");
      retorno = false;
    }
    if (celular.length < 9) {
      toast.error("O campo Telefone é inválido.");
      retorno = false;
    }

    if (this.state.ddd == "") {
      toast.error("O campo DDD é obrigatório.");
      retorno = false;
    }

    return retorno;
  }

  onSalvar() {
    let tel = this.state.telefone;
    let cpfCNpj = this.state.cpf;
    const dados = {
      cpfCnpj: cpfCNpj.padStart(11, "0"),
      ddd: this.state.ddd,
      telefone: tel,
      nome: this.state.nomeCompleto,
      certificado: this.state.certificado,
      aceite: this.state.aceite,
    };

    if (this.validate())
      axios
        .post("/api/cadastro-cliente-loja", dados)
        .then((res) => {
          if (res.data.token)
            window.location.href =
              "https://minhaconta.mms.com.vc/certificados/" + res.data.token;
          else window.location.href = "/agendamento/concluido";
        })
        .catch((err) => {
          const erro = JSON.stringify(err.response.data);
          toast.error(erro.replace('/"', ""));
        });
  }

  onKeyDdd = (e) => {
    const ddd = e.target.value;
    if (ddd.length >= 2) {
      e.preventDefault();
    }

    this.setState({ inputDDDPreenchido: true });
  };

  onKeyCelular = (e) => {
    const tel = e.target.value;
    if (tel.length >= 9) {
      e.preventDefault();
    }
    this.setState({ inputTelefonePreenchido: true });
  };

  onBlurDDD = (e) => {
    this.setState({ ddd: e.target.value });
  };

  onKeyPressNomeCompleto = (e) => {
    this.setState({ inputNomeCompletoPreenchido: true });
  };

  onBlurCelular = (e) => {
    this.setState({ celular: e.target.value });
  };

  onBlurNomeCompleto = (e) => {
    this.setState({ nomeCompleto: e.target.value });
    this.setState({ inputNomeCompletoPreenchido: true });
  };

  onBlurTelefone = (e) => {
    this.setState({ telefone: e.target.value });
  };

  render() {
    var styles = {
      msgBemvindo: { "font-size": "13px", "margin-bottom": "40px" },
      divEnderecoConfirm: {
        cursor: "pointer",
        border: "none",
        color: "#ed6d11",
        "background-color": "transparent",
        width: "100%",
        "margin-left": "14px",
        "font-size": "11px",
        "margin-top": "5px",
        "font-weight": "bold",
      },
      divEndereco: {
        cursor: "pointer",
        border: "1px solid #333",
        "border-radius": "10px",
        "border-color": "rgb(119, 118, 118)",
        "margin-bottom": "10px",
        height: "44px",
        "background-color": "white",
      },
    };

    return (
      <div>
        <div className="row logoIndex">
          <div className="col-12 faixaHeader">
            <img
              className="img_agendamento"
              alt="MMS"
              src="/assets/layout/images/logoMMS_big.png"
            />
          </div>
        </div>

        <div className="container">
          <div style={styles.msgBemvindo}>
            Por favor, preencha os campos abaixo com os dados do titular do
            certificado para concluirmos o cadastro.
          </div>

          <div style={styles.container}>
            {" "}
            <div className="row ">
              <div
                className={
                  this.state.inputNomeCompletoPreenchido === true
                    ? "col-12 divEnderecoConfirm"
                    : " col-12 divEndereco "
                }
              >
                <label>Nome Completo</label>
                <input
                  autoFocus
                  type="text"
                  onKeyPress={(e) => this.onKeyPressNomeCompleto(e)}
                  onBlur={(e) => this.onBlurNomeCompleto(e)}
                  placeholder="Nome Completo"
                  onChange={(e) =>
                    this.setState({ nomeCompleto: e.target.value })
                  }
                  value={this.state.nomeCompleto}
                />
              </div>
              <div
                className={
                  this.state.inputCPFPreenchido === true
                    ? "col-12 divEnderecoConfirm"
                    : " col-12 divEndereco "
                }
              >
                <label>CPF / CNPJe</label>
                <input
                  type="text"
                  disabled={false}
                  onKeyPress={(e) => this.onKeyPressCPF(e)}
                  placeholder="CPF"
                  onChange={(e) => this.setState({ cpf: e.target.value })}
                  value={this.state.cpf}
                />
              </div>

              <div
                className={
                  this.state.inputCertificadoPreenchido === true
                    ? "col-12 divEnderecoConfirm "
                    : " col-12 divEndereco "
                }
              >
                <label>Certificado</label>
                <input
                  type="number"
                  disabled={false}
                  onKeyPress={(e) => this.onKeyPressCertificado(e)}
                  placeholder="Certificado"
                  onChange={(e) =>
                    this.setState({ certificado: e.target.value })
                  }
                  value={this.state.certificado}
                />
              </div>

              <div
                className={
                  this.state.inputDDDPreenchido === true
                    ? "col-12 divEnderecoConfirm inputDdd_agendamento"
                    : " col-12 divEndereco inputDdd_agendamento"
                }
              >
                <label>DDD</label>
                <input
                  className="dddInput"
                  maxLength="2"
                  size="4"
                  type="number"
                  onKeyPress={(e) => this.onKeyDdd(e)}
                  onBlur={(e) => this.onBlurDDD(e)}
                  placeholder={"DDD"}
                  onChange={(e) => this.setState({ ddd: e.target.value })}
                  value={this.state.ddd}
                  max="99"
                  min="10"
                />
              </div>

              <div
                className={
                  this.state.inputTelefonePreenchido === true
                    ? "col-12 divEnderecoConfirm inputCelular_agendamento"
                    : " col-12 divEndereco inputCelular_agendamento"
                }
              >
                <label>Celular</label>

                <input
                  type="number"
                  onBlur={(e) => this.onBlurTelefone(e)}
                  onKeyPress={(e) => this.onKeyCelular(e)}
                  placeholder={"Telefone"}
                  onChange={(e) => this.setState({ telefone: e.target.value })}
                  value={this.state.telefone}
                  maxLength="9"
                  max="999999999"
                  min="111111111"
                />
              </div>

              <div className={"col-12"}>
                <input
                  name="aceite"
                  type="checkbox"
                  checked={this.state.aceite}
                  onChange={this.aceiteOnChange}
                  className={"regular-checkbox big-checkbox"}
                />
                <span>
                  {" "}
                  Deseja receber contato sobre a montagem no telefone informado?
                </span>
              </div>

              <div className="col-12">
                <button
                  type="button"
                  onClick={this.onSalvar}
                  className="btn_agendamento btn-warning"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}
