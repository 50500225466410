import React, {Component} from 'react';

export class PesquisaErro extends Component {

	componentWillMount(){
	}
	
    render() {
		
		var styles = {
			problemas: {'color': '#f0983e', 'margin': '40px', 'font-size': '20px', 'font-weight': 'bold'}
		}

        return (
            <div className="p-grid">
				<img className="logoMMSResposta" alt="MMS" src="/assets/layout/images/logoMMS.png"/>

                <div className="p-col-12">
                    <div className='txtOpiniao'>
                        <p><b>Problemas em encontrar a Pesquisa</b></p>
                    </div>
                </div>
            </div>
        );
    }
}