import React, {Component} from 'react';

import { PesquisaSatisfacao } from "./PesquisaSatisfacao";
import { PesquisaRespondida } from "./PesquisaRespondida";
import { PesquisaErro } from "./PesquisaErro";

import { AppTopbarMMS } from "../../AppTopbarMMS";

import classNames from "classnames";
import { createBrowserHistory } from "history";

import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "../../layout/layout.scss";

export class ExibePesquisa extends Component {
	constructor(props) {
		super(props);		
		this.state = {
		  layoutMode: "static",
		  layoutColorMode: "dark",
		  staticMenuInactive: true,
		  overlayMenuActive: false,
		  mobileMenuActive: false,
		  id: this.props.match.params.id
		};		
	}
  
	addClass(element, className) {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
	  }

	  removeClass(element, className) {
		if (element.classList) element.classList.remove(className);
		else
		  element.className = element.className.replace(
			new RegExp(
			  "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
			  "gi"
			),
			" "
		  );
	  }
  
	componentDidUpdate() {
		if (this.state.mobileMenuActive) this.addClass(document.body, "body-overflow-hidden");
		else this.removeClass(document.body, "body-overflow-hidden");
	}  
  
	isHome() {
		const history = createBrowserHistory();
		let path = history.location.pathname;		
		return path.indexOf("/pesquisa/") > -1;
	}
	
	isPesquisaRespondida() {
		const history = createBrowserHistory();
		let path = history.location.pathname;		
		return path.indexOf("pesquisa-respondida") > -1;		
	}
  
    render() {
		const wrapperClass = classNames("layout-wrapper", {
		  "layout-overlay": this.state.layoutMode === "overlay",
		  "layout-static": this.state.layoutMode === "static",
		  "layout-static-sidebar-inactive":
			this.state.staticMenuInactive && this.state.layoutMode === "static",
		  "layout-overlay-sidebar-active":
			this.state.overlayMenuActive && this.state.layoutMode === "overlay",
		  "layout-mobile-sidebar-active": this.state.mobileMenuActive,
		});
	
        return (	

			<div className={wrapperClass}>

				<div className="layout-main">				    					
					{this.isHome() ? <PesquisaSatisfacao id = {this.state.id} /> : this.isPesquisaRespondida() ? <PesquisaRespondida /> : <PesquisaErro />}																		
				</div>									
				
				<div className="layout-mask"></div>				
			</div>						
			
        );
    }
}