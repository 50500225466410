import React, { Component } from "react";

export class AppTopbarMMS extends Component {
  static defaultProps = {
    onToggleMenu: null,
  };

  render() {
    var styles = {
      navbar: {
        position: "fixed",
        height: "50px",
        padding: "5px 1.5em 0em 1.5em",
        color: "#ffffff",
        "z-index": "999",
        right: "0",
        background: "#fff",
        width: "100%",
        transition: "left 0.2s",
        "text-align": "center",
        "border-bottom": "3px solid #f0983e",
      },
      logoMMS: { width: "100px" },
    };

    return (
      <div className="container">
        <div className="row">
          <div className="col">
            <nav style={styles.navbar}>
              <img
                style={styles.logoMMS}
                alt="MMS"
                src="/assets/layout/images/logoMMS.png"
              />
            </nav>
          </div>
        </div>
      </div>
    );
  }
}
