import AbstractService from './AbstractService';

class PesquisaSatisfacaoService {
    constructor() {             
        this.abstractService = new AbstractService();
    }
	
    findPesquisa(id) {		
		return this.abstractService.get('/pesquisa-satisfacao/'+id+'/token');
    }

    findPerguntas(id) {       
		return this.abstractService.get('/pesquisa-satisfacao/pergunta/' + id + "/token");
    }

    salvar(dados, id) {
	  return this.abstractService.post('/pesquisa-satisfacao/' + id + "/token", dados);
    }      
}

export default PesquisaSatisfacaoService;