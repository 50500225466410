import React, {Component} from 'react';

export class ContratoEnviado extends Component {

    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="" style={{"textAlign": "center"}}>
                                       <p><b>Contrato confirmado com sucesso!</b></p>
                                </div>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-4">
                                <div className="">
                                </div>
                            </div>
                            <div className="p-col-4">
                                <div className="">
                                    <img src="/assets/layout/images/sucess.png" width="100%" alt=""/>
                                </div>
                            </div>
                            <div className="p-col-4">
                                <div className="">
                                </div>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12">
                                <div className="" style={{"textAlign": "center"}}>
                                       <p>Aguarde um SMS com os dados de acesso ao Sistema.</p>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        );
    }
}