import React, {Component} from 'react';

import {Contrato} from './Contrato';
import { ContratoEnviado } from "./ContratoEnviado";

import { AppFooter } from "../../AppFooter";
import { AppTopbarContrato } from "../../AppTopbarContrato";

import classNames from "classnames";
import { createBrowserHistory } from "history";

import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "../../layout/layout.scss";

export class ExibeContrato extends Component {
	constructor() {
		super();
		this.state = {
		  layoutMode: "static",
		  layoutColorMode: "dark",
		  staticMenuInactive: true,
		  overlayMenuActive: false,
		  mobileMenuActive: false,
		};
	}
  
	addClass(element, className) {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
	  }

	  removeClass(element, className) {
		if (element.classList) element.classList.remove(className);
		else
		  element.className = element.className.replace(
			new RegExp(
			  "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
			  "gi"
			),
			" "
		  );
	  }
  
	componentWillMount() {
		document.body.style = "background: linear-gradient(#050324, #021353d9)";
	}
	
	componentDidUpdate() {
		if (this.state.mobileMenuActive) this.addClass(document.body, "body-overflow-hidden");
		else this.removeClass(document.body, "body-overflow-hidden");
	}  
  
	isHome() {
		const history = createBrowserHistory();
		let path = history.location.pathname;	
		return path.indexOf("contratoEnviado") == -1;
	}
  
    render() {
		const wrapperClass = classNames("layout-wrapper", {
		  "layout-overlay": this.state.layoutMode === "overlay",
		  "layout-static": this.state.layoutMode === "static",
		  "layout-static-sidebar-inactive":
			this.state.staticMenuInactive && this.state.layoutMode === "static",
		  "layout-overlay-sidebar-active":
			this.state.overlayMenuActive && this.state.layoutMode === "overlay",
		  "layout-mobile-sidebar-active": this.state.mobileMenuActive,
		});
	
        return (	

			<div className={wrapperClass}>
				<AppTopbarContrato />
				
				<div className="layout-main">
				    
					<div className="p-grid">				
						<div className="p-col-12">
							<div className="card">
								{this.isHome() ? <Contrato id={this.props.match.params.id}/> : <ContratoEnviado />}								
							</div>
						</div>
					</div>
					
				</div>	
				
				<AppFooter />
				
				<div className="layout-mask"></div>
				
			</div>						
			
        );
    }
}