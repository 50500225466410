import React, { Component } from 'react';

export class AppFooter extends Component {

    render() {
        return  (
            <div className="layout-footer" style={{"textAlign": "center"}}>
                <img src="/assets/layout/images/logo_tempo.png" width="20" alt=""/>
                <span className="footer-text" style={{'marginRight': '5px'}}> Tempo Assist - Copyright &copy; 2019 - V1.0</span>
            </div>
        );
    }
}