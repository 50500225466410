import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

export class BuscarCPF extends Component {
  constructor(props) {
    super(props);
	this.state = {      
	  redirectCadastro: false
    };	
  }
  
  cadastro() {        
	 window.location.href = "/agendamento/cadastro"
  } 

  render() {	
	
	var styles = {
		btn: {'margin-top': '20px', 'width': '100%', 'border': 'none', 'border-radius': '10px', 'padding': '10px', 'background-color': '#ed6d11', 'color': 'aliceblue', 'font-weight': 'bold', 'font-size': '14px', 'height': '50px'},
		msgBemvindo: {'font-size': '13px', 'margin-bottom': '40px'},
		tituloMontagem: {'color': '#ed6d11', 'font-size': '16px', 'font-weight': 'bold','margin-bottom': '20px'}
	}
	
    return (
      <div>
        <div className="row logoIndex">
          <div className="col-12">
            <img className="img_agendamento" alt="MMS" src="/assets/layout/images/logoMMS.png" />
          </div>
        </div>

        <div className="container">
          <div style={styles.tituloMontagem}>Montagem de Móveis MMS </div>

          <div style={styles.msgBemvindo}>
            Olá! Seja bem-vindo(a) à nossa página. =)
          </div>

          <div style={styles.msgBemvindo}>
            Pra começar, clique no botão iniciar.
          </div>
          <br />
          <br />
          <div className="col-12">
            <button type="button" onClick={this.cadastro} className="btn_agendamento">
              INICIAR
            </button>
          </div>
        </div>
      </div>
    );
  }
}
