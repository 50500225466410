import React, {Component} from 'react';
import { BtnSolicitarAtendente } from "./BtnSolicitarAtendente";
import IconCheck from "./IconCheck";

export class Concluido extends Component {
   constructor(props) {
		super(props);    
   };
	
   render() {

	var styles = {
		iconePronto: {'font-size': '50px', 'color': '#ed6d11', 'text-align': 'center', 'margin-top': '30px'},
		textoFinal: {'font-size': '12px', 'color': '#333', 'text-align': 'center'},
		fieldset: {'border': '1px solid #ed6d11', 'border-radius': '8px', 'margin-top': '30px', 'min-height': '50px'},
		legend: {'text-align': 'center', 'font-weight': 'bold', 'color': '#ed6d11'}
		
	}
	
	  return (
		<section>
		  <div className="container">
			<div className="row">
			  <div className="col-12" style={styles.iconePronto}>
				<IconCheck size="70" />
			  </div>

			  <div className="col-12" style={styles.textoFinal}>
				<p>
				  <b>Cadastro Realizado!</b>
				</p>
				<p>
				  Assim que recebermos a emissão de seu(s) certificado(s) de
				  montagem, entraremos em contato via whatsapp
				</p>
				<p> Em caso de dúvidas, por favor entre em contato com:</p>
			  </div>
			</div>

			<div className="row">
			  <div className="col-12" style={styles.textoFinal}>
				<fieldset style={styles.fieldset}>
				  <legend style={styles.legend}>CASAS BAHIA</legend>
				  <b>0800 770 9350 </b>
				  ou <br />
				  <b>(11) 4020-9350 </b> para Capital e Grande SP
				</fieldset>
				<fieldset style={styles.fieldset}>
				  <legend style={styles.legend}>PONTO</legend>
				  <b>0800 770 9352 </b> ou <br />
				  (11) <b>4020-9352</b> para Capital e Grande SP{" "}
				</fieldset>
			  </div>
			</div>
			<br />
			<BtnSolicitarAtendente/>
		  </div>
		</section>
	  ); 
  }
  
}
