import React, { Component } from "react";

export class BtnSolicitarAtendente extends Component {
	constructor(props) {
		super(props);    
    };
	

	render() {
			
		var styles = {
			whats: {'max-width': '80%'},
			taCenter: {'text-align': 'center'}
		}
					
		const solicitarAtendimento = () => {
			window.open(
			  "https://wa.me/558007048970?text=Dúvidas sobre a montagem",
			  "_blank"
			);
		  };

	  return (
		<div className="col-12" style={styles.taCenter}>
		  <img className="pointer" style={styles.whats} alt="MMS" src="/assets/layout/images/whatsapp-btn.png" onClick={solicitarAtendimento} />
		</div>
	  );
		
	}
  
  
}
