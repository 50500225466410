import React, { Component } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";

import { ExibeContrato } from "./components/contrato/ExibeContrato";
import { ExibePesquisa } from "./components/pesquisa/ExibePesquisa";
import { ExibeAgendamento } from "./components/agendamento/ExibeAgendamento";

import "./App.css";

class App extends Component {
  constructor() {
    super();    
  }
 
  
  render() {
    

    return (
    
        <BrowserRouter>
            <Switch>
			              			  			  				
				<Route path="/contrato/:id" name="ExibeContrato" exact component={ExibeContrato} />
				<Route path="/contratoEnviado" name="ContratoEnviado" exact component={ExibeContrato} />              
				
				<Route path="/pesquisa/:id" name="Pesquisa" exact component={ExibePesquisa} />                                                       
				<Route path="/pesquisa-respondida" name="PesquisaRespondida" exact component={ExibePesquisa} />
				<Route path="/pesquisa-erro" name="PesquisaErro" exact component={ExibePesquisa} />              
			  
				<Route path="/agendamento" name="Agendamento" exact component={ExibeAgendamento} />	  			  			  
				<Route path="/agendamento/cadastro" name="AgendamentoCadastro" exact component={ExibeAgendamento} />
				<Route path="/agendamento/concluido" name="AgendamentoConcluido" exact component={ExibeAgendamento} />		  
			  
            </Switch>
        </BrowserRouter>
        
    );
	
  }
}

export default App;
