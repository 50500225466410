import AbstractService from './AbstractService';

class ContratoClient {
    constructor() {             
        this.abstractService = new AbstractService();
    }
	
    aceitarContrato(token) {		
		return this.abstractService.post('/contrato/' + token, null);								
    }
		
}

export default ContratoClient;