import React, {Component} from 'react';


export class PesquisaRespondida extends Component {

	componentWillMount(){

	}
	
    render() {
        return (
            <div className="p-grid">
				<img className="logoMMSResposta" alt="MMS" src="/assets/layout/images/logoMMS.png"/>

                <div className="p-col-12">
                    <div className="card">
                        <div className="p-grid">
                            <div className="txtResposta" style={{"textAlign": "center"}}>
                                <p><b>Resposta Enviada!</b></p>
                                <div className='txtOpiniao'>Obrigado! Sua opinião é muito importante para melhorarmos nosso atendimento</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}