import axios from 'axios';

const AxiosApi = axios.create({
    baseURL: "/api"
});

class AbstractClient {				
	constructor() {
		this.enableLogs = true;
    }					
	
	getHeaders() {
		var headers = {
            'Content-Type' : 'application/json',
			'accept' : 'application/json',			
        };
		
		return {
              headers: headers
        };				
	}	
	
    post(url, body) {					
		return this.doApiRequest("post", url, body);			
    }	
			
	get(url) {		
		return this.doApiRequest("get", url, null);						
    }

	doApiRequest(method, url, body) {   			  											
		return AxiosApi[method](url, body, this.getHeaders());    
	}	
		
}

export default AbstractClient;