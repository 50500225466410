import React, {Component} from 'react';

import {BuscarCPF} from './BuscarCPF';
import {CadastroCpfCnpj} from './CadastroCpfCnpj';
import {Concluido} from './Concluido';

import { createBrowserHistory } from "history";

export class ExibeAgendamento extends Component {
	constructor() {
		super();		
	}  	
  
    componentWillMount() {	  
		document.body.style = "width: 100%;overflow-x: hidden;font-size: 12px;background-color: #edf1f5;font-family:Verdana,Geneva,Tahoma,sans-serif";
	}

	isHome() {
		const history = createBrowserHistory();
		let path = history.location.pathname;	
		return path.indexOf("agendamento/concluido") == -1 && path.indexOf("agendamento/cadastro") == -1;
	}
  
	isCadastro() {
		const history = createBrowserHistory();
		let path = history.location.pathname;	
		return path.indexOf("agendamento/cadastro") > -1;
	}
	  
    render() {		
	
        return (	
		
			<div className="layout-main">											
				{this.isHome() ? <BuscarCPF /> : this.isCadastro() ? <CadastroCpfCnpj /> : <Concluido />}																		
			</div>						
			
        );
    }
}