import React, {Component} from 'react';

export class AppTopbarContrato extends Component {

    static defaultProps = {
        onToggleMenu: null
    }

    render() {
        return (
            <div className="layout-topbar">
                <span className="layout-topbar">
                    <div className="container">
                        <div className="row" style={{"textAlign": "center"}}>
                            <div className="col">
                                CONTRATO DE SERVIÇOS DE MONTAGEM
                            </div>
                        </div>
                    </div>
                </span>
            </div>
        );
    }
}